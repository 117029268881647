.menuItemContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.menuItemLeft {
  display: flex;
  align-items: center;
  gap: 10px
}

.menuItemRight {
  display: flex;
  align-items: center;
  gap: 5px
}

.menuItemIcon {
  width: 20px;
  height: 20px;
  border-radius: 5px;
}

.menuItemIcon2 {
  width: 16px;
  height: 16px;
  filter: brightness(70%) invert(1) grayscale(100%);
}