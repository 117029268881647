@import '../styles/variables.module.scss';

.faqContainer {
  box-sizing: border-box;
  width: 100%;

  display: flex;
  justify-content: center;
}

.faq {
  display: flex;
  flex-direction: column;
  gap: 40px;
  max-width: 750px;
  width: 100%;
}

.faqTitle {
  color: $heading-color;
  text-align: center;
  font-family: $body-font;
  font-size: 32px;
  font-weight: 700;
}

.faqList {
  box-sizing: border-box;
  padding: 5px 30px;
  background-color: $secondary-color;
  border-radius: 8px;
}

.faqTop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  color: $heading-color;
  font-family: $body-font;
  font-size: 22px;
  font-weight: 700;
  line-height: 22px;

  padding: 30px 0;

  .faqTopTitle {
    margin-top: 5px;
  }

  cursor: pointer;
}

.faqBottom {
  padding-bottom: 30px;

  color: $grey-color;
  font-family: $body-font;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.plusContainer {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-left: 20px;
}

.faqSeparator {
  height: 1px;
  width: 100%;
  background-color: $stroke-color;
}

@media (max-width: 750px) {
  .faqTop{
    font-size: 18px;
    padding: 24px 0;
  }
}

@media (max-width: 600px) {
  .faqList {
    padding: 0 20px;
  }

  .faqTitle {
    font-size: 24px;
  }

  .faqTop{
    font-size: 16px;
    padding: 20px 0;
  }

  .faqBottom {
    font-size: 14px;
  }
}

@media (max-width: 400px) {
  .faqTitle {
    font-size: 20px;
  }
}



.verificationLevels {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.levelSection {
  display: flex;
  gap: 16px;
  padding: 14px 0px;
}

.levelSectionHighlight {
  background-color: #1E2741;
}

.levelLeft {
  height: 24px;

  font-size: 20px;
  color: $heading-color;
  font-weight: 500;

  display: flex;
  align-items: center;
  gap: 8px;
}

.levelLeftBadge {
  width: 23px;
}

.levelRight {
  padding-top: 2px;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
}
