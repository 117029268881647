@import '../../styles/variables.module.scss';

.header {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.title {
  color: $heading-color;
  font-family: $body-font;
  font-weight: 700;
  font-size: 40px;

  @media (max-width: 600px) {
    font-size: 26px;
  }
}

.subTitle {
  color: $body-color;
  font-family: $body-font;
  font-weight: 500;

  @media (max-width: 600px) {
    font-size: 14px;
  }
}

.lastUpdatedContainer {
  display: flex;
  justify-content: end;
  width: 100%;
  margin-top: 10px;

  @media (max-width: 600px) {
    margin-top: 20px;
  }
}

.lastUpdated {
  color: $body-color;
  font-family: $body-font;
  font-weight: 300;
  font-size: 14px;
}

.dashboard {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.top {
  width: 100%;
  gap: 20px;
  display: flex;
  flex-direction: column;

  .analyticsItem {
    width: 100%;
    box-sizing: border-box;
  }
}

.bottom {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  .analyticsItem {
    width: calc(33.333% - 13.333px);
    aspect-ratio: 1 / 1;
    box-sizing: border-box;

    @media (max-width: 750px) {
      width: 100%;
    }
  }
}

.analyticsItem {
  border-radius: 10px;
  background-color: $secondary-color;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.totalDepositedSection {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.deposited {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: -30px;
}

.depositedAmount {
  font-size: 50px;
  font-family: $body-font;
  font-weight: 300;
  letter-spacing: .1em;
  color: $heading-color;
  margin-bottom: 5px;

  @media (min-width: 750px) AND (max-width: 900px) {
    font-size: 36px
  }
}

.depositedTimeframe {
  font-size: 18px;
  font-family: $body-font;
  font-weight: 300;
  color: $pink-color;

  @media (min-width: 750px) AND (max-width: 900px) {
    font-size: 16px
  }
}

.tokenBreakdownSection, .marketShareSection {
  padding: 0px 20px;

  @media  (max-width: 500px) OR ((min-width: 750px) AND (max-width: 900px)) {
    padding: 0px 10px;
  }
}

.multiChartSection {
  padding: 10px 15px 0 10px;

  @media (max-width: 750px) {
    padding: 10px 10px 0 10px;
  }
}