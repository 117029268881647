// HomePage.module.scss
@import '../../styles/variables.module.scss';

.title {
  font-size: 36px;
  font-family: $body-font;
  color: $heading-color;
  font-weight: 700;
  margin-bottom: 10px;
}

.meta {
  font-size: 14px;
  font-family: $body-font;
  color: #c6c6c6;
  font-weight: 600;
  margin-bottom: 35px;
}

.featureImage {
  width: 100%;
  border-radius: 10px;
  margin-bottom: 35px;
}

/* Blog Content Styling */
.content {
  font-size: 18px;
  line-height: 1.6;
  color: #c6c6c6;
}

/* Handle headings */
.content h1, .content h2, .content h3 {
  color: $heading-color;
  font-weight: bold;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}

.content h2 {
  font-size: 28px;
}

.content h3 {
  font-size: 22px;
}

/* Paragraphs */
.content p {
  margin-bottom: 1rem;
  font-size: 18px;
}

/* Images */
.content img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  margin: 0.5rem 0;
}

/* Blockquotes */
.content blockquote {
  border-left: 4px solid #007bff;
  padding-left: 1rem;
  font-style: italic;
  color: #555;
  margin: 1.5rem 0;
}

/* Lists */
.content ul {
  list-style: disc;
  padding-left: 2rem;

  ::marker {
    font-size: 28px;
  }
}

.content ol {
  list-style: decimal;
  padding-left: 2rem;
}

/* Links */
.content a {
  color: $pink-color;
  text-decoration: none;
  font-weight: 600;
  transition: 0.3s;
}

.content a:hover {
  color: #0056b3;
}

/* Code blocks */
.content pre {
  background-color: #f5f5f5;
  padding: 1rem;
  border-radius: 8px;
  overflow-x: auto;
}

.content code {
  font-family: monospace;
  background-color: #eee;
  padding: 2px 4px;
  border-radius: 4px;
}
