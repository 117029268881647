@import './variables.module.scss';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;550;600;700&display=swap');

html {
  background-color: $primary-color;
}

html, body, #root {
  margin: 0;
  padding: 0;
  font-family: Inter, Arial, sans-serif;
}


.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.page-content {
  flex: 1;
}

.content-container {
  max-width: 1140px;
  margin: 0 auto;

  @media (max-width: 1180px) {
    margin: 0 25px;
  }

  @media (max-width: 600px) {
    margin: 0 15px;
  }
}


.blog-container {
  max-width: 700px;
  margin: 0 auto;

  @media (max-width: 740px) {
    margin: 0 25px;
  }

  @media (max-width: 500px) {
    margin: 0 15px;
  }
}

.content-main-container {
  margin-top: 40px;
  margin-bottom: 40px;

  @media (max-width: 600px) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}



