@import '../../styles/variables.module.scss';

.analyticsItems {
  display: flex;
  width: 100%;
  gap: 10px;
  margin-bottom: 10px;
  flex-wrap: wrap;
}

.analyticsTitle {
  text-align: center;
}

.analyticsNumber {
  text-align: center;
  color: $grey-color;
}

.analyticsItem {
  background-color: $stroke-color;
  padding: 8px 10px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.chartContainer {
  height: 500px;
  position: relative;
}

.watermark {
  position: absolute;
  opacity: 0.5;
  right: 5px;
  bottom: 100px;
}