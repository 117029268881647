@import '../../../styles/variables.module.scss';

.controls {
  background-color: $secondary-color;
  color: $heading-color;
  font-size: 16px;
  display: flex;
  gap: 20px;
  padding: 10px 20px;
  box-sizing: border-box;
  border-radius: 10px;
}

.control {
  display: flex;
  align-items: center;
  gap: 10px;
}

.control3 {
  width: 100%;
  justify-content: flex-end;
}

.timeframeButtons {
  display: flex;
  align-items: center;
  gap: 15px;
}

.timeframeButton {
  height: 34px;
  background-color: #222947;
  line-height: 34px;
  padding: 0 12px;
  border-radius: 5px;
  color: $heading-color;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    background-color: lighten(#242b4c, 5%); // Lighten the color by 20%
  }
}

.timeframeButton.active {
  background-color: $stroke-color;
}


@media (max-width: 860px) {
  /* Medium screens */
  .controls {
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .control3 {
    flex-basis: 100%;
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 620px) {
  /* Small screens */
  .controls {
    flex-direction: column;
    align-items: center;
  }
}