// HomePage.module.scss
@import '../../styles/variables.module.scss';

.content {
  width: 100%;
}

.header {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.title {
  display: flex;
  align-items: center;
  color: $heading-color;
  font-family: $body-font;
  font-weight: 700;
  gap: 12px;
  
  span {
    font-size: 40px;
  }

  div {
    background-color: $red-color;
    font-size: 18px;
    padding: 4px 7px;
    border-radius: 5px;
  }
}

.subTitle {
  color: $body-color;
  font-family: $body-font;
  font-weight: 500;
}

.tableContainer {
  max-width: 1140px;
  overflow-y: hidden;
  overflow-x: auto;
  margin-bottom: 20px;
  margin-top: 10px;
}

.scoringContainer {
  display: flex;
  justify-content: flex-end;
}

.scoringButton {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 12px;
  padding: 6px 12px;
  background-color: #F434D5;
  color: $heading-color;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 700;
  margin-top: 10px;
}

.table {
  width: 1140px;
  border-collapse: seperate; /* Ensure no space between table cells */
}

.tableRow {
  height: 35px;
  background-color: $secondary-color;
  border-radius: 5px;

  font-size: 14px;
  font-weight: 550;
  font-family: $body-font;
  color: $heading-color;

  th, td {
    vertical-align: middle;
  }

  th {
    &:nth-child(1) { width: 6%; }
    &:nth-child(2) { width: 9%; }
    &:nth-child(3) { width: 11%; }
    &:nth-child(4) { width: 14%; }
    &:nth-child(5) { width: 19%; }
    &:nth-child(6) { width: 17%; }
    &:nth-child(7) { width: 14%; }
  }
}

.tableRowHoverable:hover {
  background-color: #191f35;
  cursor: pointer;
}

.tableGap {
  height: 5px;
}

.tableRowContent {
  background-color: #1C2339;
  font-family: $body-font;

  td {
    border-radius: 0 0 4px 4px;
    width: 100%;
    box-sizing: border-box;
  }
}

.tableRowContentContainer {
  display: flex;
  flex-direction: row;  
  padding: 10px;
  box-sizing: border-box;
}

.rowSection {
  padding: 0 10px 0 10px;
  min-width: 15%;
}

.rowSection:last-child {
  flex-grow: 1;
}

.rowSectionSeperator {
  align-self: stretch;
  width: 1px;
  margin: 0 10px;
  background-color: #2D395C;
}

.rowPropertyName {
  color: $grey-color;
  font-size: 12px;
  font-weight: 500;
  padding-bottom: 10px;
}

.rowPropertyValue {
  color: $heading-color;
  font-size: 12px;
  font-weight: 500;
  text-align: right;
}

.rowSectionTitle {
  height: 15px;
  padding-bottom: 12px;
  font-size: 13px;
  font-family: $body-font;
  color: $heading-color;
  font-weight: 600;
  padding-right: 20px;
}

.tableDataLeft {
  text-align: left;
}

.tableDataRight {
  text-align: right;
}

.tableDataStart {
  padding-left: 20px;
  border-radius: 4px 0px 0px 4px;
}

.tableDataEnd {
  padding-right: 20px;
  border-radius: 0px 4px 4px 0px;
}

.tableDataDropped {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.casinoIcon {
  display: flex;
  align-items: center;

  img {
    border-radius: 4px;
    margin-right: 6px;
  }
}

.scoreBox {
  padding: 3px 6px;
  border-radius: 5px;
  background-color: #212535;
  display: inline-block;
  min-width: 50px;
  text-align: center;
  font-size: 13px;
  color: $heading-color;
  font-weight: 600;
}

.dropdownButton {
  margin-top: 3px;

  &:hover {
    cursor: pointer;
  }
}

@media (max-width: 600px) {
  .content {
    margin-top: 20px;
  }

  .title {
    span {
      font-size: 26px;
    }

    div {
      font-size: 14px;
    }
  }

  .subTitle {
    font-size: 14px;
  }

  .table {
    width: 100%;
  }

  .tableRow {

    th {
      &:nth-child(1) { width: 18%; }
      &:nth-child(2) { width: 25%; }
      &:nth-child(3) { width: 25%; }
    }
  }

  .tableRowContentContainer {
    flex-direction: column;
    width: 100%;
    padding-top: 12px;
  }

  .rowSection {
    align-self: stretch;
    flex-grow: 1;

    table {
      width: 100%;
    }
  }

  .rowSectionSeperator {
    align-self: stretch;
    height: 1px;
    width: unset;
    margin: 2px 10px 10px 10px;
  }

  .hideOnMobile {
    display: none;
  }
}

.logs {
  color: $pink-color;
}