@import '../../../styles/variables.module.scss';

.tableDataLeft {
  text-align: left;
}

.tableDataRight {
  text-align: right;
}

.tableDataStart {
  padding-left: 20px;
  border-radius: 4px 0px 0px 4px;
}

.tableDataEnd {
  padding-right: 20px;
  border-radius: 0px 4px 4px 0px;
}

.baseTableContainer {
  overflow-x: auto;
  height: calc(100% - 40px);
}

::-webkit-scrollbar {
  height: 7px; /* optional for horizontal scrollbars */
  width: 7px;
  background-color: #1b1d26;
}

::-webkit-scrollbar-thumb {
  background: #2d3140;
  border-radius: 5px;
}


.baseTable {
  width: 100%;
  min-width: 700px;

  tr {
    height: 35px;
    background-color: $tertiary-color;
  }

  .rowGap {
    height: 5px;
  }

  th, td {
    vertical-align: middle;
  }
}

.casinoKey {
  display: flex;
  align-items: center;
  gap: 10px;

  img {
    width: 20px;
    height: 20px;
    border-radius: 5px;
  }
}