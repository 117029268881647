@import '../../../styles/variables.module.scss';

.marketShareSection {
  padding: 20px;

  @media (max-width: 750px) {
    padding: 10px;
  } 

  color: #fff
}
