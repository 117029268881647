@import '../../../styles/variables.module.scss';

.lineChartSection {
  padding: 20px;

  @media (max-width: 750px) {
    padding: 10px;
  } 
}

.chart {
  width: 100%;
  aspect-ratio: 2 / 1;
  display: flex;
  flex-direction: column;
  box-sizing: border-boxS;
  position: relative;
}

.chartKey {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  margin-bottom: 15px;

  @media (max-width: 750px) {
    margin-bottom: 10px;
  } 
}

.keyItem {
  display: flex;
  gap: 5px;
  align-items: center;
}

.keyText {
  color: #aaa;
  font-family: $body-font;
  font-size: 16px;
}

.keyColor {
  width: 10px;
  height: 10px;
}

@media (max-width: 750px) {
  .chartKey {
    gap: 12px;
  }

  .keyText {
    font-size: 12px;
  }

  .keyColor {
    width: 8px;
    height: 8px;
  }
}

.chartControls {
  display: flex;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 10px;

  @media (max-width: 500px) {
    gap: 8px;
  }

  @media (max-width: 374px) {
  }
}

.chartDataButton {
  height: 34px;
  background-color: #222947;
  line-height: 34px;
  padding: 0 12px;
  border-radius: 5px;
  color: $heading-color;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    background-color: lighten(#242b4c, 5%); // Lighten the color by 20%
  }
}

.chartDataButton {
  @media (max-width: 500px) {
    font-size: 12px;
    padding: 0 8px;
  }
}

.chartDataButton.active {
  background-color: $stroke-color;
}




.watermark {
  position: absolute;
  top: 25px;
  right: 25px;
  opacity: 0.5;

  .mobile {
    display: none;
  }

  @media (max-width: 500px) {
    .desktop {
      display: none;
    }

    .mobile {
      display: block;
    }

    top: 30px;
    right: 25px;
  }
}