@import '../../styles/variables.module.scss';

.content {
  width: 100%;
  font-family: $body-font;
  color: $body-color;
  font-size: 16px;
  font-weight: 500;
}

.noCreatorElement {
  width: 100%;
  display: flex;
  justify-content: center;

  div {
    padding: 16px 24px; 
    background-color: $tertiary-color;
    color: $heading-color;
    font-family: $body-font;
    font-size: 18px;
    font-weight: 600;
    border-radius: 6px;
  }
}

.layout {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .top {
    display: flex;
    gap: 24px;

    .topLeft {
      display: flex;
      flex-direction: column;
      gap: 24px;
      width: 250px;
      min-width: 250px;
      height: 500px;

      position: sticky;
      top: 20px; /* Stick to the top */
      left: 0; /* Stick to the left */
    }
    
    .topRight {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      gap: 24px;

      width: calc(100% - 250px - 24px);

      // overflow-y: auto;
      // height: calc(100vh - 100px);
    }
  }
}

.sectionHeader {
  font-size: 32px;
  font-weight: 700;
  color: $heading-color;
  margin-bottom: 14px;
}

.sectionSubHeader {
  font-size: 22px;
  font-weight: 700;
  color: $heading-color;
}

.description {
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 40px;
}

.profileCard, .currentVerificationSection, .about, .currentDeals, .dealHistory, .streams {
  background-color: $secondary-color;
  border-radius: 8px;
  padding: 34px;
}

.profileCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  height: auto;
}

.pfpImg {
  position: relative;
  margin-bottom: 20px;

  img {
    border-radius: 100%;
  }
}

.pfpLevel {
  top: -5px;
  right: 0;
  position: absolute;
  width: 36px;
}

.pfpName {
  color: $heading-color;
  text-align: center;
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 20px;
}

.pfpSocials {
  display: flex;
  gap: 8px;
}


.currentVerificationSection {
  padding: 25px 0 10px 0;
  align-self: flex-start;
  height: auto;
}

.currentVerificationTitle {
  font-size: 20px;
  font-weight: 700;
  color: $heading-color;
  margin: 0 20px 15px 20px;
}

.verificationLevels {
  display: flex;
  flex-direction: column;
}

.levelSection {
  display: flex;
  gap: 16px;
  padding: 14px 20px;
}

.levelSectionHighlight {
  background-color: #1E2741;
}

.levelLeft {
  height: 24px;

  font-size: 15px;
  color: $heading-color;
  font-weight: 500;

  display: flex;
  align-items: center;
  gap: 8px;
}

.levelLeftBadge {
  width: 19px;
}

.levelRight {
  padding-top: 2px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}


.socials {
  display: flex;
  gap: 16px;
  overflow-x: auto;
  margin: 20px 0;
}

.social {
  box-sizing: border-box;
  width: 120px;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  background-color: #1E2741;
  border-radius: 8px;
}

.socialFollowers {
  text-align: center;
  font-family: $body-font;
  color: $heading-color;
  font-size: 16px;
  font-weight: 600;
}

.dealsContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.deal, .stream {
  background-color: #1E2741;
  border-radius: 8px;
}

.dealTop, .streamTop {
  display: flex;
  cursor: pointer;
  box-sizing: border-box;
  align-items: center;
  padding: 15px 25px;
}

.noData {
  cursor: default;
}

.dealTitle, .streamTitle {
  display: flex;
  flex-grow: 1;
  align-items: center;
}

.streamTitle {
  flex-direction: column;
  align-items: flex-start;
  text-wrap: nowrap;
  overflow: hidden;
  box-sizing: border-box;

  gap: 3px;
  color: $grey-color;
}

.dealCasino {
  font-size: 22px;
  font-weight: 600;
  margin-right: 10px;
  color: $heading-color;
}

.liveIcon {
  background-color: $red-color;
  width: 14px;
  height: 14px;
  border-radius: 100%;
  display: inline-block;
  margin-right: 6px;
}

.dealBadge {
  width: 23px;
  min-width: 23px;
  margin-right: 22px;
}

.smallDates {
  display: none;
}

.dealDropdown {
  display: flex;
  align-items: center;
  margin-left: 15px;
}

.rotate {
  transform: rotate(180deg);
}

.dealContent, .streamContent {
  border-top: 1px solid $stroke-color;
  padding: 26px 24px;

  display: flex;
  flex-direction: column;
  gap: 20px;
}

.dealPlatform {
  display: flex;
  align-items: center;
  gap: 10px;
  
  font-size: 20px;
  color: $heading-color;
  font-weight: 600;
}

.dealSubTitle {
  font-size: 20px;
  color: $heading-color;
  font-weight: 600;
  margin-bottom: 28px;
}

.dealInfoPieces {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100%;
  gap: 16px;
}

.dealInfoPiece {
  box-sizing: border-box;
  background-color: $secondary-color;
  border-radius: 8px;
  height: 105px;
  
  display: flex;
  flex-direction: column; 
  align-items: center;
  justify-content: center;
  gap: 14px;
  max-width: 180px;
}

.dealInfoText {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  padding: 0 20px;
  text-wrap: nowrap;
}

.streamContent {
  max-width: 99%;
}

.streamGraph {
  max-width: 100%;
}

.faq {
  margin-top: 16px;
}

@media (max-width: 900px) {
  .streamTitle {
    flex-direction: column;
    align-items: flex-start;
    text-wrap: nowrap;
    overflow: hidden;
  }

  .layout {
    .top {
      flex-direction: column;
  
      .topLeft { 
        min-width: unset;
        width: 100%;
        flex-direction: row;
        position: unset;
        height: unset;
      }

      .topRight {
        width: 100%;
      }
    }
  }

  .profileCard {
    box-sizing: border-box;
    width: 250px;
    flex-grow: 1;
  }

  .dealInfoPieces {
    display: flex;
    gap: 16px;
    overflow-x: auto;
    flex-wrap: unset;
  }
  
  .dealInfoPiece {
    box-sizing: border-box;
    background-color: $secondary-color;
    height: 105px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    border-radius: 8px;
  }
}

@media (max-width: 600px) {
  .largeDates {
    display: none;
  }

  .smallDates {
    display: unset;
  }

  .profileCard, .currentVerificationSection, .about, .currentDeals, .dealHistory, .streams {
    border-radius: 6px;
    padding: 24px;
  }

  .currentVerificationSection {
    padding: 18px 0;
  }

  .sectionHeader {
    font-size: 28px;
    margin-bottom: 14px;
  }
  
  .sectionSubHeader {
    font-size: 20px;
  }
  
  .description {
    margin-bottom: 30px;
  }
}

@media (max-width: 500px) {
  .layout {
    gap: 16px;

    .top {
      gap: 16px;

      .topLeft { 
        gap: 16px;
        flex-direction: column;
      }

      .topRight {
        gap: 16px;
      }
    }
  }

  .profileCard {
    width: 100%;
  }

  .pfpName {

  }

  .social {
    width: 100px;
    height: 100px;
  }

  .sectionHeader { 
    font-size: 24px;
  }

  .profileCard, .currentVerificationSection, .about, .currentDeals, .dealHistory, .streams {
    padding: 16px;
  }

  .currentVerificationSection {
    padding: 16px 0;
  }

  .description {
    margin-bottom: 20px;
  }

  .dealTop {
    padding: 10px 15px;
  }

  .dealCasino {
    font-size: 18px;
    margin-right: 8px;
  }

  .dealBadge {
    width: 21px;
    min-width: 21px;
    margin-right: 14px;
  }

  .dealContent {
    padding: 16px 14px;
  }

  .dealSubTitle {
    font-size: 18px;
    margin-bottom: 18px;
  }

  .dealInfoText {
    font-size: 14px;
  }

  .smallDates {
    font-size: 14px;
  }
}