@import '../styles/variables.module.scss';

.footer {
  border-top: 1px solid $stroke-color;
  position: relative;
}

.footerContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding: 0 5px;

  font-family: $body-font;
  font-size: 14px;
  color: $heading-color;
  font-weight: 500;
}


.footerContent__right {
  display: flex;
  align-items: center;
  text-align: right;
  gap: 30px;
  margin-left: 20px;

  a {
    color: #fff;
  }

  @media (max-width: 600px) {
    gap: 20px;
  }
}