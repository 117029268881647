// HomePage.module.scss
@import '../../styles/variables.module.scss';

.contactContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.contact {
  background-color: $secondary-color;
  width: 600px;
  padding: 25px 20px;
  border-radius: 10px;
}

.header {
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
  text-align: center;
  padding: 0 25px;

  font-family: $body-font;
  margin-bottom: 30px;
}

.title {
  font-weight: 700;
  font-size: 40px;
  color: $heading-color;
}

.subTitle {
  font-weight: 500;
  color: $body-color;
  font-size: 16px;
  line-height: normal;
}

@media(max-width: 500px) {
  .header {
    padding: 0 0px;
  }

  .title {
    font-size: 32px;
  }

  .subTitle {
    font-size: 16px;
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: 15px;

  button {
    height: 40px;
    background-color: $pink-color;
    margin-top: 15px;
    border-radius: 5px;
    border: none;
    font-size: 16px;
    font-weight: 700;
    color: $heading-color;
    font-family: $body-font;
  }
}

.formGroup {
  label {
    display: block;

    font-family: $body-font;
    font-weight: 500;
    color: $grey-color;

    margin-bottom: 8px;

    span {
      color: #E73729;
    }
  }

  input {
    height: 42px;
  }

  textarea {
    height: 120px;
  }

  input, textarea {
    box-sizing: border-box;
    width: 100%;
    background-color: $tertiary-color;
    border: none;
    padding: 12px 20px;
    border-radius: 5px;
    color: $heading-color;
    font-family: $body-font;
  }
}