// AboutPage.module.scss
@import '../../styles/variables.module.scss';

.content {
  width: 100%;
}

.title {
  display: flex;
  align-items: center;
  color: $heading-color;
  font-family: $body-font;
  font-weight: 700;
  gap: 12px;
  
  span {
    font-size: 40px;
  }

  margin-bottom: 30px;
}

.body {
  background-color: $secondary-color;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.section {
}

.sectionTitle {
  color: $heading-color;
  font-family: $body-font;
  font-weight: 700;
  font-size: 35px;
  margin-bottom: 15px;
}

.sectionContent {
  color: $grey-color;
  font-family: $body-font;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  ul {
    padding-left: 20px; /* Add padding to align bullets */
    margin-top: 6px; /* Add margin to separate from text */

    li {
      list-style-type: disc;
      margin-bottom: 6px;
    }
  } 
}

.supporters {
  display: flex;
  gap: 12px;
  margin-top: 15px;
  flex-wrap: wrap;

  img {
    height: 82px;
    border-radius: 10px;
  }
}

@media (max-width: 600px) {
  .body {
    padding: 15px 10px;
  }

  .title {
    span {
      font-size: 26px;
    }

    margin-bottom: 20px;
  }

  .sectionTitle {
    font-size: 26px;
  }

  .sectionContent {
    font-size: 14px;
  }

  .supporters {
    img {
      height: 60px;
    }
  }
}