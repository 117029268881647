@import '../../../styles/variables.module.scss';

.sectionHeader {

  display: flex;
  align-items: center;
  gap: 8px;

  font-family: $body-font;
  color: $heading-color;
  margin-bottom: 20px;

  @media (max-width: 750px) {
    margin-bottom: 10px;
  }
}

.title {
  font-size: 18px;
  font-weight: 500;

  @media (max-width: 750px) {
    font-size: 16px;
  }

  @media (max-width: 350px) {
    font-size: 14px;
  }
}

.tag {
  display: flex;
  align-items: center;
  gap: 5px;

  color: #cecece;
  background-color: $stroke-color;
  border-radius: 10px;
  padding: 5px 8px;
  font-size: 12px;

  img {
    width: 12px;
    height: 12px;
    filter: brightness(50%) invert(1) grayscale(100%);
  }

  @media (max-width: 400px) {
    gap: 3px;
    font-size: 10px;

    img {
      width: 10px;
      height: 10px;
    }
  }
}

.logo {
  display: flex;
  align-items: center;
  margin-left: auto;

  @media (max-width: 500px) {
    display: none;
  }

  opacity: 0.5;
}