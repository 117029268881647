// .badge {
//   position: relative;
//   display: inline-flex;
// }

// .level {
//   text-align: center;
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   margin: auto 0;
//   font-size: 14px;
//   font-weight: 700;
//   color: white;
//   pointer-events: none; /* Prevent interfering with mouse events on the SVG */
// }

.badge {
  position: relative;
  width: 100%;
  height: 90%;
  margin-top: 10%;
}

.badge svg {
  width: 100%;
  height: 100%;
}

.badgeText {
  position: absolute;
  top: 45%;
  transform: translate(0, -50%); /* Center the text horizontally and vertically */
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  color: white;
  width: 100%;
  text-align: center;
}