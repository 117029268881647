@import '../styles/variables.module.scss';

.communityContainer {
  box-sizing: border-box;
  width: 100%;
  border-radius: 8px;
  padding: 36px 42px;
  background-color: $secondary-color;

  display: flex;
}

.communityContent {
  flex-grow: 1;
  padding-right: 30px;
}

.communityTitle {
  font-family: $body-font;
  color: $heading-color;
  font-size: 32px;
  font-weight: 700;

  margin-bottom: 20px;
}

.communityDescription {
  color: $grey-color;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 30px;

  display: flex;
  flex-direction: column;
  gap: 6px;
}

.communityButtons {
  display: flex;
  gap: 20px;
}

.discordButton, .xButton {
  display: inline-flex;
  padding: 12px 22px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 5px;
  background-color: #F434D5;

  font-family: $body-font;
  color: $heading-color;
  font-size: 14px;
  font-weight: 700;
}

.discordButton {
  background-color: #5865F2;
}

.xButton {
  background-color: #000;
}

.communityIcon {
  display: flex;
  align-items: center;
  opacity: 0.2;
  mix-blend-mode: luminosity;   
}

@media (max-width: 750px) {

  .communityContainer {
    padding: 20px 24px;
  }

  .communityIcon {
    display: none;
  }

  .communityContent {
    padding: 0;
  }

  .communityTitle {
    margin-bottom: 12px;
  }

  .communityDescription {
    margin-bottom: 20px;
  }
}

@media (max-width: 600px) {
  .communityTitle {
    font-size: 24px;
  }

  .communityDescription {
    font-size: 14px;
  }

  .discordButton, .xButton {
    padding: 8px 16px;
    font-size: 12px;
  }
}

@media (max-width: 450px) {
  .communityButtons {
    flex-direction: column;
    gap: 10px;
  }

  .xButton, .discordButton {
    flex-grow: 0;
  }
}