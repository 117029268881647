@import '../../styles/variables.module.scss';

.scoreBox {
  padding: 3px 6px;
  border-radius: 5px;
  background-color: #212535;
  display: inline-block;
  min-width: 47px;
  text-align: center;
  font-size: 13px;
  color: $heading-color;
  font-weight: 600;
}

.container {
  width: 100%;
  background-color: $secondary-color;
  
  z-index: 10;
  border-radius: 10px;
  overflow: hidden;


  display: flex;
  flex-direction: row;
}

.left {
  flex-grow: 1;
  overflow-x: auto; // Allow horizontal scrolling
  overflow-y: hidden; // Prevent vertical scrolling
}

.header, .mobileHeader {
  height: 75px;
  border-bottom: 1px solid $stroke-color;
  flex-wrap: wrap;

  display: flex;
  align-items: center;

  h1 {
    color: $heading-color;
    font-family: $body-font;
    font-size: 28px;
    font-weight: 700;
    margin-left: 20px;
    flex-grow: 1
  }

  .casinoSelector {
    margin: 0 20px;
  }
}

.mobileHeader {
  padding-right: 20px;
  border-bottom: none;
}

.categorySection {
  padding: 0 0px;
  font-size: 13px;
  font-family: $body-font;
  font-weight: 500;

  table {
    width: 100%;
    tr .rowPropertyValue {
      text-align: right;
    }
  }
}

.categorySectionDivider {
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;

  div {
    height: 1px;
    flex-grow: 1;
    background-color: $stroke-color;
    margin: 10px 0;
  }

  span {
    color: $grey-color;
    font-family: $body-font;
    font-size: 20px;
    font-weight: 600;
    padding: 0 10px;
  }
}

.rowSectionTitle {
  font-size: 16px;
  color: $heading-color;
  font-weight: 600;
  padding-bottom: 12px;
  line-height: 20px;
}

.rowPropertyName {
  color: $grey-color;
  padding-bottom: 8px;
}

.rowPropertyValue {
  color: $heading-color;
}

.categoryScore {
  display: flex;
  justify-content: end;
}

.casinoSelector {

}

.right {
  background-color: $tertiary-color;
  width: 265px;
  min-width: 265px;
  height: 100%;
  box-sizing: border-box;

  .rightHeader {
    height: 75px;
    display: flex;
    align-items: center;
    flex-grow: 1;
      border-bottom: 1px solid $stroke-color;
      padding: 0 20px;
  }

  .rightContent {
    padding: 20px;
  }

  h1 {
    color: $heading-color;
    font-family: $body-font;
    font-size: 28px;
    font-weight: 600;
    flex-grow: 1;
  }
}


.closeButton {
  box-sizing: border-box;
  display: flex;
  width: 30px;
  height: 30px;
  padding: 18px;
  border-radius: 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $stroke-color;

  svg {

    width: 14px;
    height: 14px;
    flex-shrink: 0;
  }
} 

.calculationMetrics {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
}

.calculationMetric:first-child span {
  color: $heading-color;
  font-size: 18px;
}

.calculationMetric {
  display: flex;

  font-family: $body-font;

  span {
    flex-grow: 1;
    color: $grey-color;
    font-size: 17px;
  }

  .rankingBox {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $heading-color;
    font-size: 14px;
    background-color: $stroke-color;
    height: 21px;
    width: 40px;
    border-radius: 4px;

  }
}

.content {
  padding: 20px;
  overflow-x: auto;

  h1 {
    color: $heading-color;
    font-family: $body-font;
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 10px;
  }
}

.configuratorTable {
  width: 100%;
}

.configuratorRow {
  background-color: $tertiary-color;
  
  td {
    height: 40px;
    color: $body-color;
    font-family: $body-font;
    font-weight: 600;
    font-size: 14px;
    vertical-align: middle; // Center items vertically
  }

  td:first-child {
    border-radius: 4px 0 0 4px;
    padding-left: 20px;


  }

  td:nth-child(3) {
    div {
     

      .configuratorRowField {
        flex-grow: 1;
      }
    }
  }

  td:last-child {
    border-radius: 0 4px 4px 0;
    padding-right: 20px;
  }
}


.configuratorTitle {
  color: $heading-color;
  font-family: $body-font;
  font-size: 26px;
  font-weight: 600;
  padding-bottom: 25px;
}

.configuratorSubTitle {
  color: $heading-color;
  font-family: $body-font;
  font-size: 24px;
  font-weight: 500;
  padding-bottom: 15px;
}

.topConfigSubTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.restoreDefaults {
  color: $pink-color;
  font-size: 14px;
  font-weight: 700;
  font-family: $body-font;
  cursor: pointer;
}

.configuratorRowField {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 200px;
}

.configuratorRowGap {
  min-width: 20px;
  height: 13px;
}

.configuratorRowScore {
  padding: 4px 5px;
  border-radius: 3px;
  border: 1px solid #404A68;
  color: $grey-color;
  font-size: 12px;
  white-space: nowrap; // Prevent text from wrapping
}

.yesNoContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.yesNoButton {
  width: 100px;
  height: 28px;
  display: flex;
  background-color: $secondary-color;
  border-radius: 3px;
}

.yesButton, .noButton {
  width: 50%;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.yesNoSelected {
  background-color: $stroke-color;
  cursor: default;
}

.casinoIcon {
  display: flex;
  align-items: center;

  img {
    border-radius: 4px;
    margin-right: 8px;
  }
}

.infoText {
  position: relative;
  display: inline-block;
  text-decoration: dotted underline;
  text-underline-offset: 2px;
  text-decoration-color: #5d5e65;
}

.infoText .infoTextDesc {
  visibility: hidden;
  width: 150px;
  background-color: $stroke-color;
  color: #fff;
  font-family: $body-font;
  text-align: center;
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  bottom: 180%;
  left: 0;
  white-space: pre-line;
}

.infoText .infoTextDesc::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 10px;
  border-width: 5px;
  border-style: solid;
  border-color: $stroke-color transparent transparent transparent;
}

.infoText:hover .infoTextDesc {
  visibility: visible;
}

.mobileHeader {
  display: none;
}

@media (max-width: 950px) {
  .container {
    flex-direction: column-reverse;
  }

  .content {
    padding: 15px 10px;
  }

  .left {
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .right {
    width: 100%;
    min-width: 100%;
  }

  .header {
    display: none;
  }

  .right .rightHeader {
    display: none;
  }

  .mobileHeader {
    display: flex;
  }

  .configuratorRowField {
    min-width: 100px;
  }

  .configuratorRowGap {
    min-width: 10px;
  }

  .configuratorRow {
    td:first-child {
      padding-left: 10px;
    }
  
    td:last-child {
      padding-right: 10px;
    }
  }
}

@media(max-width: 600px) {

  .configuratorRowField {
    font-size: 11px;
  }

  .configuratorRowScore {
    font-size: 9px;
  }

  .yesNoButton {
    width: 55px;
    height: 24px;
    font-size: 9px;
  }

  .configuratorSubTitle {
    font-size: 20px;
  }
}

@media (max-width: 500px) {
  .mobileHeader {
    justify-content: space-between;
  }

  .mobileHeader h1 {
    display: none;
  }
}