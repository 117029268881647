// HomePage.module.scss
@import '../../styles/variables.module.scss';

.content {
  width: 100%;
}

.header {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.title {
  display: flex;
  align-items: center;
  color: $heading-color;
  font-family: $body-font;
  font-weight: 700;
  gap: 12px;
  
  span {
    font-size: 40px;
  }

  div {
    background-color: $red-color;
    font-size: 18px;
    padding: 4px 7px;
    border-radius: 5px;
  }
}

.subTitle {
  color: $body-color;
  font-family: $body-font;
  font-weight: 500;
}

.sectionTitle {
  color: $heading-color;
  font-family: $body-font;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  display: flex;
  align-items: center;
  gap: 10px;

  margin-top: 40px;
  margin-bottom: 15px;
}

.streamerTag {
  font-size: 10px;
  background-color: $stroke-color;
  padding: 4px 7px;
  border-radius: 6px;
  font-weight: 500;
  margin-top: 2px;
}

.leaderboardTitle {
  margin-top: 50px;
}

.liveCircle {
  border-radius: 100%;
  width: 15px;
  height: 15px;
  background-color: $red-color;
}

.liveCards {
  display: flex;
  gap: 16px;
  overflow-x: auto;
  padding-bottom: 5px;
}

.liveCard {
  box-sizing: border-box;
  min-width: 196px;
  border-radius: 8px;
  background-color: $secondary-color;
  padding: 17px;

  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 14px;
}

.liveCardPfp {
  position: relative;

  img {
    border-radius: 100%;
  }
}

.pfpLevel {
  top: -5px;
  right: 0;
  position: absolute;
  width: 27px;
}

.liveCardViewers {
  box-sizing: border-box;
  background-color: $tertiary-color;
  border-radius: 5px;
  padding: 3px 10px;

  color: $heading-color;
  font-family: $body-font;
  font-size: 13px;
  font-weight: 500;

  display: flex;
  align-items: center;
  gap: 5px;
}

.liveIcon {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: #E73729;
  margin-right: 4px;
}

.viewersText {
  flex-grow: 1;
  text-align: center;
}

.linkIcon {
  width: 16px;
  height: 16px;
}

.liveCardName {
  text-align: center;
  font-family: $body-font;
  font-size: 20px;
  font-weight: 700;
  color: $heading-color;
}

.casinoContainer {
  display: flex;
  align-items: center;
  gap: 5px;
  color: $heading-color;
  font-family: $body-font;
  font-size: 15px;
  font-weight: 600;

  img {
    border-radius: 4px;
  }
}

.liveCardProfile {
  box-sizing: border-box;
  text-align: center;
  background-color: $stroke-color;
  padding: 6px 10px;
  width: 100%;
  border-radius: 4px;
  color: $heading-color;
  font-family: $body-font;
  font-size: 12px;
  font-weight: 600;
}

.emptyContainer {
  width: 100%;
  display: flex;
  align-items: center;

}

.emptyElement {
  padding: 12px 20px;
  background-color: $tertiary-color;
  border-radius: 4px;
  color: $heading-color;
  font-family: $body-font;
  font-size: 16px;
  font-weight: 500;
}

@media (max-width: 600px) {
  .hideOnMobile {
    display: none;
  }
}

@media (max-width: 600px) {
  .content {
    margin-top: 20px;
  }

  .title {
    span {
      font-size: 26px;
    }

    div {
      font-size: 14px;
    }
  }

  .subTitle {
    font-size: 14px;
  }

  .sectionTitle {
    font-size: 24px;
  }
}