
.logo {
  display: flex;
  align-items: center;

  img {
    height: 100%;
  }

  span {
    margin-right: 10px;
    color: #fff;
    font-family: Verdana;
    font-weight: 700;
  }
}