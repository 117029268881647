@import '../styles/variables.module.scss';

.navbarContainer {
  width: 100%;
  background-color: $secondary-color;
  position: relative;
}

.navbar {
  height: 80px;
  display: flex;
  align-items: center;
}

.links {
  margin-left: auto;
  z-index: 100;

  ul {
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 25px;

    a li {
      color: $grey-color;
      font-family: $body-font;
      font-size: 18px;
      font-weight: 550;

      &:hover {
        color: $heading-color;
      }
    }

    a.activeLink li {
      color: $heading-color;
    }
  }
}

.menuIcon {
  display: none;

  color: white;
  background-color: $tertiary-color;

  width: 44px;
  height: 44px;
  border-radius: 5px;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  user-select: none;
}

@media (max-width: 800px) {
  .navbar {
    flex-direction: row-reverse;
  }

  .menuIcon {
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .links {
    margin-left: 0;
    display: none;

    ul {
      background-color: $menu-color;
      position: absolute;
      top: 80px;
      right: 0;
      flex-direction: column;
      gap: 0;
      width: 100%;

      a {
        box-sizing: border-box;
        width: 100%;
        padding: 16px 0;
        padding-left: 26px;
        border-bottom: 1px solid $menu-stroke-color;
      }
    }
  }

  .showNavbar {
    display: block;
  }
}