@import '../styles/variables.module.scss';

.programContainer {
  margin: 50px 0;

  box-sizing: border-box;
  width: 100%;
  border-radius: 8px;
  padding: 36px 42px;
  background-color: $secondary-color;

  display: flex;
}

.programContent {
  flex-grow: 1;
  padding-right: 30px;
}

.programTitle {
  font-family: $body-font;
  color: $heading-color;
  font-size: 32px;
  font-weight: 700;

  margin-bottom: 20px;
}

.programDescription {
  color: $grey-color;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 30px;

  display: flex;
  flex-direction: column;
  gap: 6px;
}

.programButton {
  display: inline-flex;
  padding: 12px 22px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 5px;
  background-color: #F434D5;

  font-family: $body-font;
  color: $heading-color;
  font-size: 14px;
  font-weight: 700;
}

.programIcon {
  display: flex;
  align-items: center;
  opacity: 0.2;
  mix-blend-mode: luminosity;   
}

@media (max-width: 750px) {

  .programContainer {
    padding: 20px 24px;
  }

  .programIcon {
    display: none;
  }

  .programContent {
    padding: 0;
  }

  .programTitle {
    margin-bottom: 12px;
  }

  .programDescription {
    margin-bottom: 20px;
  }
}

@media (max-width: 600px) {
  .programTitle {
    font-size: 24px;
  }

  .programDescription {
    font-size: 14px;
  }
}