// HomePage.module.scss
@import '../../styles/variables.module.scss';


.header {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.title {
  display: flex;
  align-items: center;
  color: $heading-color;
  font-family: $body-font;
  font-weight: 700;
  gap: 12px;
  
  span {
    font-size: 40px;
  }

  div {
    background-color: $red-color;
    font-size: 18px;
    padding: 4px 7px;
    border-radius: 5px;
  }
}

.subTitle {
  color: $body-color;
  font-family: $body-font;
  font-weight: 500;
}

.signUpSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
}

.signUpHeader {
  color: $heading-color;
  font-family: $body-font;
  font-weight: 700;
  font-size: 36px;
  margin-bottom: 30px;
  max-width: 650px;
  text-align: center;
}

.signUpSubHeader {
  color: $body-color;
  font-family: $body-font;
  font-weight: 500;
  margin-bottom: 35px;
}

@media (max-width: 650px) {
  .signUpHeader {
    font-size: 24px;
    max-width: 400px;
  }

  .signUpSubHeader {
    font-size: 14px;
  }
}

@media (max-width: 450px) {
  .signUpHeader {
    font-size: 20px;
    max-width: 340px;
  }
}

.blogsHeader {
  margin-top: 55px;
  color: $body-color;
  font-family: $body-font;
  font-weight: 700;
  border-bottom: 1px solid #e6e6e615;
  padding-bottom: 10px;
}

.blogCards {
  margin-top: 10px;
  display: grid;
  gap: 28px 24px;
  align-items: start;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

@media (max-width: 1000px) {
  .blogCards {
    margin-top: 10px;
    display: grid;
    gap: 16px 24px;
  }
}

@media (max-width: 675px) {
  .blogCards {
    gap: 10px 24px;
  }
}

.blogCard {
  margin: 20px 0;

  border-radius: 4px;

  display: flex;
  flex-direction: column;

  gap: 20px;

  border-radius: 5px;

  font-size: 14px;
  font-weight: 400;
  font-family: $body-font;
  color: $heading-color;

  img {
    width: 100%;
    border-radius: 5px;

    transition: transform 0.3s ease-in-out;
  }

  .title {
    font-size: 20px;
  }

  .excerpt {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: $body-color;
    padding: 14px 0;
  }

  .date {
    color: #c8c8c8;
  }
}

.blogCard:hover img {
  transform: translateY(-6px);
}


.pagination {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-top: 1rem;
}

.pagination button {
  background-color: #007bff;
  border: none;
  color: #fff;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.pagination span {
  font-weight: bold;
}
