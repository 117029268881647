// HomePage.module.scss
@import '../../styles/variables.module.scss';

.controlsContainer {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  gap: 40px;
}

.searchContainer {
  position: relative;
  width: 100%;
  display: flex;
}

.searchInput {
  padding: 8px 12px 8px 36px;
  width: 300px;
  border-radius: 4px;
  border: 0px solid #ccc;
  background-color: #1C2339;
  color: #E4E4E4;
  font-family: $body-font;
}

@media (max-width: 600px) {
  .searchInput {
    width: 100%;
  }
}


/* Change placeholder text color to white */
.searchInput::placeholder {
  color: #E4E4E4;
  opacity: 1; /* Override default opacity in some browsers */
}

/* Optional: Focus state styling */
.searchInput:focus {
  outline: none;
  border-color: none;
}

.searchIcon {
  position: absolute;
  top: 50%;
  left: 12px; /* Position the icon 12px from the left */
  transform: translateY(-50%);
  color: #aaa; /* Icon color */
  pointer-events: none; /* Allow clicks to pass through to the input */
}

/* Optional: Change icon color on focus */
.searchInput:focus + .searchIcon {
  color: #fff;
}


/* Dropdown Container */
.dropdownContainer {
  flex: 0 0 125px; /* Fixed width for dropdown */
  position: relative;
}

/* Dropdown Styles */
.dropdown {
  width: 100%;
  padding: 8px 12px;
  border-radius: 4px;
  border: 0px solid #ccc;
  background-color: #1C2339;
  color: white;
  appearance: none; /* Removes default arrow */
  background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12"><path fill="%23fff" d="M6 9.41L1.41 5 0 6.41l6 6 6-6L10.59 5z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 12px;
  cursor: pointer;

  color: #E4E4E4;
  font-family: $body-font;
}

.dropdown:focus {
  outline: none;
  // border-color: #555;
  // background-color: #444;
}





.tableContainer {
  max-width: 1140px;
  overflow-y: hidden;
  overflow-x: auto;
  margin-bottom: 20px;
  margin-top: 10px;
}

.table {
  width: 1140px;
  border-collapse: seperate; /* Ensure no space between table cells */
}

.tableRow {
  height: 35px;
  background-color: $secondary-color;
  border-radius: 5px;

  font-size: 14px;
  font-weight: 500;
  font-family: $body-font;
  color: $heading-color;

  th, td {
    vertical-align: middle;
  }

  th {
    &:nth-child(1) { width: 16.667%; }
    &:nth-child(2) { width: 16.667%; }
    &:nth-child(3) { width:  16.667%; }
    &:nth-child(4) { width:  16.667%; }
    &:nth-child(5) { width:  16.667%; }
    &:nth-child(6) { width:  16.667%; }
  }
}

.tableRowHeader {
  font-weight: 700;
}

.tableRowHoverable:hover {
  background-color: #191f35;
  cursor: pointer;
}

.tableGap {
  height: 5px;
}

.tableDataLeft {
  text-align: left;
}

.tableDataCenter {
  text-align: center;
}

.tableDataRight {
  text-align: right;
}

.tableDataStart {
  padding-left: 20px;
  border-radius: 4px 0px 0px 4px;
}

.tableDataEnd {
  padding-right: 20px;
  border-radius: 0px 4px 4px 0px;
}

.tableDataDropped {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.tablePagination {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  .paginationControls {
    display: flex;
    gap: 20px;
    font-family: $body-font;
    color: #7B858C;
    font-size: 14px;
    font-weight: 600;

    .pageAvailable {
      color: #CDCDCD;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.creatorContainer {
  display: flex;
  align-items: center;

  gap: 8px;

  img {
    border-radius: 100%;
  }
}

.tableTag, .tableTag2, .tableTag3 {
  background-color: $tertiary-color;
  padding: 4px 5px;
  font-size: 10px;
  font-weight: 500;
  border-radius: 4px;
  color: #fff;
}

.tableTag {
  background-color: #FDDBBB;
}

.tableTag3 {
  background-color: #A2D2DF;
}

.tags {
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: center;
}



.levelContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.badgeContainer {
  width: 17px;
}

.casinoContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    border-radius: 4px;
    margin-right: 6px;
  }
}

.followersBox {
  box-sizing: border-box;
  display: inline-flex;
  height: 23px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;

  background-color: $tertiary-color;
  border-radius: 5px;
}

.streamLink {
  color: $pink-color;
}

.viewBox {
  box-sizing: border-box;
  display: inline-flex;
  height: 25px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 5px;
  background-color: $stroke-color;
  font-size: 12px;
  color: $heading-color;
  font-family: $body-font;
}

@media (max-width: 600px) {
  .hideOnMobile {
    display: none;
  }
}

@media (max-width: 600px) {
  .table {
    width: 100%;
  }

  .tableRow {

    th {
      &:nth-child(0) { width: 25%; }
      &:nth-child(2) { width: 33%; }
      &:nth-child(6) { width: 25%; }
      // &:nth-child(4) { width: 20%; }
    }
  }

  .tableRowHeader {
    font-size: 13px;
  }

  .tableRowContentContainer {
    flex-direction: column;
    width: 100%;
    padding-top: 12px;
  }

  .rowSection {
    align-self: stretch;
    flex-grow: 1;

    table {
      width: 100%;
    }
  }

  .rowSectionSeperator {
    align-self: stretch;
    height: 1px;
    width: unset;
    margin: 2px 10px 10px 10px;
  }
}

@media (max-width: 470px) {
  .tableRowHeader {
    font-size: 12px;
  }

  .viewBox {
    height: 20px;
    padding: 7px;
    font-size: 10px;
    color: $heading-color;
    font-family: $body-font;
  }

  .tableDataStart {
    padding-left: 15px;
  }

  .tableDataEnd {
    padding-right: 15px;
  }

  // .tableRow {

  //   th {
  //     &:nth-child(1) { width: 20%; }
  //     &:nth-child(2) { width: 10%; }
  //     &:nth-child(3) { width: 40%; }
  //     // &:nth-child(4) { width: 20%; }
  //   }
  // }
}