$primary-color: #111525;
$secondary-color: #161C31;
$tertiary-color: #1C2339;
$pink-color: #CC58F7;

$red-color: #E73729;

$menu-color: #181E35;
$menu-stroke-color: #1E2741;

$stroke-color: #2D395C;

$heading-color: #FFFFFF;
$body-color: #E4E4E4;
$grey-color: #B8B8B8;

$body-font: Inter;